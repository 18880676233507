<template>
  <div>

    <!-- 3D   greenball  blueball-->
    <div class="res-open-content type-s3s lgk3">
      <div class="r-o-s3s">
        <table class="com-table">
          <tr>
            <th>期数</th>
            <th width="140">时间</th>
            <th>第一球</th>
            <th>第二球</th>
            <th>第三球</th>
            <th>总和</th>
            <th>大小</th>
            <th>单双</th>

          </tr>
          <tr v-for="item in list" :key="item.Expect">
            <td>{{item.Expect}}</td>
            <td>{{item.Opentime|substrSome}}</td>
            <td v-for="code in item.codeArr" :key="code" class="td-ball">
              <div :class="'blueball b'+code"></div>
            </td>
            <!-- <td class="td-ball">
              <div class="blueball b4"></div> 
            </td> -->
            <td>{{item.lutuResult[0]}}</td>
            <td :class="fontColor[item.lutuResult[2]]"> {{item.lutuResult[2]}} </td>
            <td :class="fontColor[item.lutuResult[1]]"> {{item.lutuResult[1]}} </td>

          </tr>
        </table>
        <div class="page">
          <!-- :page-sizes="[20,50,100,200]" -->
          <el-pagination @current-change="handleCurrentChange" :current-page="pages.pagenum" :page-size="pages.pagecount" layout="total, prev, pager, next, jumper" :total="totals">
          </el-pagination>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import kjmixins from "../mixins/kjmixins";
export default {
  name: "kjk3",
  props: [""],
  mixins: [kjmixins],
  data() {
    return {};
  },

  components: {},
  created() {},
  computed: {},

  methods: {},
};
</script>
<style  scoped>
.r-o-s3s .com-table tr td .ds_s,
.r-o-s3s .com-table tr td .dx_x {
  color: #00f;
}
.r-o-s3s .com-table tr td .ds_d,
.r-o-s3s .com-table tr td .dx_d {
  color: red;
}
</style>